<template>
  <div
    :class="{
      'w-48': open,
    }"
    class="w-20 h-screen relative ml-2"
  >
    <div
      :class="{
        'w-48': open,
      }"
      class="w-20 duration-300 flex flex-col justify-between h-full fixed bg-nSidebarBg pt-6"
    >
      <div class="flex flex-col">
        <img
          :src="
            require('@/assets/images/catalog-sidebar/arrow-left-circle.svg')
          "
          :class="{
            'transform rotate-180': !open,
          }"
          class="arrow-left-circle absolute -right-3 w-7 rounded-full cursor-pointer"
          @click="open = !open"
        />
        <Logo :open="open" class="place-self-center" />
        <ul class="pt-10">
          <li
            v-for="(item, index) in menus"
            :key="index"
            :class="{
              'mt-9': item.gap,
              'bg-white border-r-4 border-nSidebarRed text-nSidebarRed':
                $route.params.tag === item.id,
            }"
            class="text-white hover:text-nSidebarRed text-sm cursor-pointer p-2 hover:bg-white mt-2 group"
          >
            <div
              @click="redirectToObkCatalogPage(item.id)"
              class="flex items-center gap-x-4 cursor-pointer pl-5"
            >
              <div class="w-6 h-6">
                <div class="block group-hover:hidden">
                  <img
                    :src="
                      require('@/assets/images/catalog-sidebar/icons/' +
                        item.src +
                        '-selected.svg')
                    "
                    v-if="$route.params.tag === item.id"
                    class="w-6 h-6"
                  />
                  <img
                    :src="
                      require('@/assets/images/catalog-sidebar/icons/' +
                        item.src +
                        '.svg')
                    "
                    v-else
                    class="w-6 h-6"
                  />
                </div>
                <div class="hidden group-hover:block">
                  <img
                    :src="
                      require('@/assets/images/catalog-sidebar/icons/' +
                        item.src +
                        '-selected.svg')
                    "
                    class="w-6 h-6"
                  />
                </div>
              </div>
              <div
                :class="{
                  'hidden origin-left duration-200': !open,
                }"
                class="flex flex-row"
              >
                <span class="font-semibold">{{ item.title }}</span>
                <span class="text-sm text-gray-400 font-semibold"
                  >&nbsp;({{ item.count }})</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Footer :open="open" class="mb-4 mx-3" />
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Common/Sidebar/Logo";
import Footer from "@/components/Common/Sidebar/FooterPlain";

export default {
  name: "SidebarCatalog",
  components: { Logo, Footer },
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.menuItemsList();
  },
  data() {
    return {
      open: true,
      menus: [],
    };
  },
  methods: {
    menuItemsList() {
      for (var i = 0; i < this.menuItems.length; i++) {
        var menuItemName = this.menuItems[i].name;
        var menuItemId = this.menuItems[i].id;
        if (menuItemId == "-1") {
          menuItemId = "all";
        }
        this.menus.push({
          id: menuItemId,
          title: menuItemName,
          count: this.menuItems[i].count,
          src: menuItemName.toLowerCase(),
          gap: false,
        });
      }
    },
    redirectToObkCatalogPage(tagId) {
      this.$router.push({
        name: "CreateOnboardingKitCatalog",
        params: {
          onboarding_kit_id: this.$route.params.onboarding_kit_id,
          tag: tagId,
        },
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.arrow-left-circle {
  top: 4.6rem;
}
</style>
