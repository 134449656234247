<template>
  <div class="text-black flex" v-if="data">
    <Sidebar :menuItems="data.tags" />
    <div class="font-semibold flex-1 h-screen">
      <Navbar title="Configure Onboarding Kit" />
      <div id="catalog-content" class="overflow-hidden">
        <div class="flex items-end justify-between">
          <KitName
            :onboarding_kit_id="onboardingKitId"
            :kit_name="kitName"
            class="ml-6"
          />
          <div class="mr-7 mb-3">
            <router-link
              :to="{
                name: 'OBKitAddedExps',
                params: { onboarding_kit_id: onboardingKitId },
              }"
              class="transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none"
              v-if="totalExpsInKit != 0"
            >
              View added experiences ({{ totalExpsInKit }})
            </router-link>
            <div
              class="transition shadow duration-300 ease-out tracking-wider bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none cursor-not-allowed"
              v-else
            >
              View added experiences ({{ totalExpsInKit }})
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 px-7 pt-2 mb-6">
          <div
            v-for="(item, index) in data.experiences"
            :key="index"
            class="border border-gray-100 flex flex-col rounded-lg shadow-md"
          >
            <div class="flex flex-row">
              <div class="w-7/12">
                <img :src="item.icon" class="rounded-l-lg" />
              </div>
              <div class="w-5/12 px-4 flex flex-col justify-between">
                <div class="pt-4">
                  <div class="text-xl">
                    {{ item.name }}
                  </div>
                  <div class="text-sm font-normal">
                    {{ item.description }}
                  </div>
                  <div
                    class="flex gap-2 justify-start mt-6"
                    v-if="item.flags.is_available"
                  >
                    <div
                      @click.prevent="goToExpForm(item.id)"
                      class="rounded-md bg-nButtonBlue text-white cursor-pointer uppercase py-2 px-2.5 text-center font-medium"
                    >
                      <p v-if="item.flags.exp_in_kit">Edit</p>
                      <p v-else>Customize</p>
                    </div>
                    <div
                      class="rounded-md bg-white border border-nButtonGreen text-nButtonGreen cursor-pointer uppercase py-2 px-2 text-center font-medium"
                      @click.prevent="showExpDemo(item.name, item.demo)"
                    >
                      View demo
                    </div>
                  </div>
                </div>
                <div
                  :class="{
                    hidden: item.flags.is_available,
                  }"
                  class="h-full flex items-center justify-center"
                >
                  <img
                    :src="require('@/assets/images/catalog/coming-soon.png')"
                    class="w-2/3"
                  />
                </div>
              </div>
              <div v-if="demoTitle">
                <CatalogExpDemo
                  :modal="modal"
                  :title="demoTitle"
                  :link="demoLink"
                  @closeModal="modal.show = false"
                ></CatalogExpDemo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Sidebar from "@/components/Common/Sidebar/Catalog";
import Navbar from "@/components/Common/Navbar/OnboardingKit";
import KitName from "@/components/OnboardingKit/KitComponents/KitName";
import CatalogExpDemo from "@/components/Modals/CatalogExpDemo.vue";

export default {
  name: "CreateOnboardingKitCatalog",
  components: { Sidebar, Navbar, KitName, CatalogExpDemo },
  data() {
    return {
      dataFetched: true,
      data: null,
      onboardingKitId: this.$route.params.onboarding_kit_id
        ? this.$route.params.onboarding_kit_id
        : "-1",
      tag: this.$route.params.tag ? this.$route.params.tag : "all",
      totalExpsInKit: 0,
      kitName: null,
      demoTitle: null,
      demoLink: null,
      modal: {
        show: false,
        heading: "Experience Demo",
        data: {},
      },
    };
  },
  mounted() {
    const vue = this;
    ApiService.get(apiResource.onboardingKit.catalog, {
      onboarding_kit_id: this.onboardingKitId,
      tag: this.tag,
    })
      .then(({ data }) => {
        vue.dataFetched = true;
        vue.data = data.data;
        vue.kitName = data.data.kit_name;
        vue.totalExpsInKit = data.data.total_exps_in_kit;
      })
      .catch(() => {});
  },
  computed: {},
  methods: {
    getCategoryIconPath(category, expCategories) {
      const obj = expCategories.filter((cat) => cat.id === category.id)[0];
      if (obj) {
        return category.slug;
      } else {
        return category.slug + "-inactive";
      }
    },
    goToExpForm(expId) {
      if (this.onboardingKitId == "-1") {
        ApiService.post(apiResource.onboardingKit.create, {
          name: this.kitName,
        })
          .then(({ data }) => {
            this.onboardingKitId = data.data.onboarding_kit_id;
            this.redirectToExpForm(expId);
          })
          .catch(() => {});
      } else {
        this.redirectToExpForm(expId);
      }
    },
    redirectToExpForm(expId) {
      this.$router.push({
        name: "OBKitExperienceForm",
        params: {
          experience_id: expId,
          onboarding_kit_id: this.onboardingKitId,
        },
      });
    },
    showExpDemo(name, link) {
      if (link != null) {
        this.demoTitle = name;
        this.demoLink = link;
        this.modal.show = true;
      }
    },
  },
};
</script>

<style></style>
