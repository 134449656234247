<template>
  <div>
    <p
      :class="{
        hidden: !open,
      }"
      class="text-sm text-gray-400"
    >
      © Texperia Techsolutions Pvt Ltd. All rights reserved.
    </p>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "SidebarFooter",
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      data: null,
      ticketTextPart1: null,
      ticketTextPart2: null,
      ticketTextPart3: null,
    };
  },
  mounted() {
    const vue = this;
    ApiService.get(apiResource.dashboard.getTicketDetails)
      .then(({ data }) => {
        vue.data = data.data;
        if (vue.data.plan_slug === "trial") {
          vue.ticketTextPart1 = "You are on a ";
          vue.ticketTextPart2 = "free";
          vue.ticketTextPart3 = " trial";
        } else {
          vue.ticketTextPart1 = "You have ";
          vue.ticketTextPart2 = vue.data.tickets_left;
          vue.ticketTextPart3 = " tickets left";
        }
      })
      .catch(() => {});
  },
  methods: {},
  computed: {},
};
</script>

<style scoped>
.tickets {
  background: #393939;
}
.tickets-button {
  background: #ff5670;
}
.ticket-text {
  color: #ff8598;
}
</style>
